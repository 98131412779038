export default{
    about: {
        h2_f1: "About us",
        description1:"Susong Jintian Clothing Co., LTD, founded in 2018, is located in Building 1, No. 8, Longfei Road, Economic Development Zone, Susong Garment Industrial Park, Anqing City, Anhui Province. With a total area of more than 2000 square meters, the company has standardized industrial plants, reasonable regional division, and production and operation management in strict accordance with the standardized 6S and ISO9001 quality system requirements.",
        description2:"At present, the OEM, ODM labeling and processing businesses of the company are distributed at home and abroad, with domestic well-known brands, domestic e-commerce, cross-border e-commerce, and well-known brands in Japan, Korea, Russia, Turkey, the United States and Europe and other countries;",
        description3:"Susong Jintian Clothing Co., LTD is mainly engaged in the design and development, production, import and export trade, brand supply, e-commerce supply, medical protection product manufacturing and other fields of four seasons clothing at home and abroad; It produces all kinds of middle and high grade men's and women's fashion, outdoor wear, children's wear, business wear, school uniforms and other full categories of clothing products, provides high-quality products and perfect supply chain services for many domestic and foreign brand customers, and is one of the core suppliers of many well-known brands at home and abroad; The products are sold at home and abroad, and have been praised and recognized by domestic and foreign customers.",
        description4:"The company has complete hardware facilities, advanced fully automatic intelligent filling machine, cotton filling machine, intelligent automatic template quilting machine, pattern machine and various special imported equipment, which can meet the needs of any clothing production for equipment. It has complete staffing, more than 100 excellent technology and high-quality skilled sewing workers, and 4 fully equipped production lines; It has more than 50 people including R&D teams, cutting personnel, post sorting inspection, packaging personnel, and company management personnel; Efficient management enables the monthly production capacity to reach 20000~30000 pieces.",
        description5:"Susong Jintian Clothing Co., LTD advocates a unique corporate culture to enhance its core competitiveness. The company actively fulfilled its social responsibilities and was awarded the vice president unit by Susong Garment Industry Association in the second year of its establishment. The company adheres to the concept of 'Going far needs to be down-to-earth, moving forward step by step from the near, and the pursuit is endless' and operates with integrity.",
        description6:"We firmly believe that God helps those who help themselves. With the support of our customers and the unremitting efforts of our employees, we will be able to achieve healthy and rapid development, and achieve a win-win situation for customers, employees and the factory. We will be diligent and vigorous, and strive to become a benchmark in the industry and bring benefits to the people.",
        evaluate:"evaluate",
        evaluate_title:"evaluation of us",
        evaluate_desc1:"The company has a first-class team, and every employee is a passionate and intelligent talent who puts their minds into the development of the company",
        evaluate_desc2:"The company has a comprehensive management system to help every employee create a good atmosphere in their work, so that employees can work with peace of mind!",
        evaluate_desc3:"The company's technical level is top-notch, with numerous experts and high-quality technical personnel able to apply technology to practice, creating greater value for the company!",
        anonymous:"anonymous",
        p1:"LLC SINUS is a comprehensive enterprise that combines scientific research and design work, production, and sales. ",
        p2:"The head office is located in Beijing, developing the brands WHY SHINE and MANSTAFF. Our representative office is located in Moscow.",
        p3:"We produce down jackets, coats, trench coats, coats, and other clothing items, as well as accessories for clothing, packaging materials, hardware, labels, and much more. 。",
        p4:"China covers a full range of services."
    },
    navbar:{
        home:"Home",
        about:"About",
        product:"Product display",
        team:"Team",
        contact:"Contact Us",
    },
    product:{
        h2_f2:"Product display",
        menswear:"Menswear",
        frock:"Women's clothing",
        children:"Children's clothing",
        configuration:"Our configuration",
        configuration_desc1:"Skilled, high-quality, skilled sewing worker",
        configuration_desc2:"Standard and fully equipped flow production line",
        configuration_desc3:"Monthly production capacity",
        configuration_desc4:"Total company area"
    },
    team:{
        h2_f3:"Our team",
        team_desc1:"Advanced plate making equipment", 
        team_desc2:"Intelligent and fully automated filling equipment",
        team_desc3:"Spacious and bright working environment",
        team_desc4:"The hard-working JINTIAN people",
        team_desc5:"Intelligent automatic template quilting machine",
        team_desc6:"Staff dormitory building with high configuration and humanization",
        h2_f4:"Cooperative brands"
    },
    contact:{
        follow:"Follow us",
        h2_f5:"Contact Us",
        address:"address",
        address_desc:"Building 1, No. 8, Longfei Road, Economic Development Zone, Susong Garment Industrial Park, Anqing City, Anhui Province",
        manage:"Lili Zhang",
        letter:"Write us a letter",
        name:"name",
        mailbox:"mailbox",
        problem:"problem",
        information:"information",
        send:"Send",
        description:"Jintian Clothing Co., LTD"
    }
}