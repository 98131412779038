<template>
  		<section id="social-section">
            
            <!-- Begin page header-->
           <div class="page-header-wrapper">
               <div class="container">
                   <div class="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                       <h2>{{$t('contact.follow')}}</h2>
                       <div class="devider"></div>
                       <!-- <p class="subtitle">Follow us on social networks</p> -->
                   </div>
               </div>
           </div>
           <!-- End page header-->
           
           <div class="container">
               <ul class="social-list">
                   <li> <a href="#" class="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.3s"><span class="rotate-box-icon"><i class="fa fa-facebook"></i></span></a></li>
                   <li> <a href="#" class="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.4s"><span class="rotate-box-icon"><i class="fa fa-twitter"></i></span></a></li>
                   <li> <a href="#" class="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.5s"><span class="rotate-box-icon"><i class="fa fa-google-plus"></i></span></a></li>
                   <li> <a href="#" class="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.6s"><span class="rotate-box-icon"><i class="fa fa-pinterest-p"></i></span></a></li>
                   <li> <a href="#" class="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.7s"><span class="rotate-box-icon"><i class="fa fa-tumblr"></i></span></a></li>
                   <li> <a href="#" class="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.8s"><span class="rotate-box-icon"><i class="fa fa-dribbble"></i></span></a></li>                      
               </ul>
           </div>
           
       </section>
       <!-- End social section -->
           
           
           
           
       <!-- Begin contact section -->
       <section id="contact-section" class="page text-white parallax" data-stellar-background-ratio="0.5" style="background-image: url(img/map-bg.jpg);">
       <div class="cover"></div>
       
            <!-- Begin page header-->
           <div class="page-header-wrapper">
               <div class="container">
                   <div class="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                       <h2>{{$t('contact.h2_f5')}}</h2>
                       <div class="devider"></div>
                       <!-- <p class="subtitle">All to contact us</p> -->
                   </div>
               </div>
           </div>
           <!-- End page header-->
           
           <div class="contact wow bounceInRight" data-wow-delay="0.4s">
               <div class="container">
                   <div class="row">
                   
                       <div class="col-sm-6" >
                           <div class="contact-info">
                               <h4>Москва</h4>
                               <ul class="contact-address">
                                   <li style="line-height: 20px;"><i class="fa fa-map-marker fa-lg"></i>&nbsp; Город Москва, улица Нижняя Красносельская, дом 40/12,корпус 21 ,этаж 2,<br>&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp; помещение 229А, Деловой центр «Новь».</li>
                                   <li><i class="fa fa-user"></i>&nbsp; Фируза</li>
                                   <li><i class="fa fa-phone"></i>&nbsp; 7(903)288-84-69</li>
                                   <!-- <li><i class="fa fa-print"></i>&nbsp; 1 -234 -456 -7890</li> -->
                                   <li><i class="fa fa-envelope"></i> i@llc-sinus.ru</li>
                                   <!-- <li><i class="fa fa-skype"></i> Unika-Design</li> -->
                               </ul>
                           </div>
                       </div>
                   
                       <div class="col-sm-6">
                           <div class="contact-form">
                               <h4>{{$t('contact.letter')}}</h4>
                               <form role="form">
                                   <div class="form-group">
                                       <input type="text" class="form-control input-lg" :placeholder="$t('contact.name')" required>
                                   </div>
                                   <div class="form-group">
                                       <input type="email" class="form-control input-lg" :placeholder="$t('contact.mailbox')" required>
                                   </div>
                                   <div class="form-group">
                                       <input type="text" class="form-control input-lg" :placeholder="$t('contact.problem')" required>
                                   </div>
                                   <div class="form-group">
                                       <textarea class="form-control input-lg" rows="5" :placeholder="$t('contact.information')" required></textarea>
                                   </div>
                                   <button type="submit" class="btn wow bounceInRight" data-wow-delay="0.8s">{{$t('contact.send')}}</button>
                               </form>
                           </div>	
                       </div>

                       <div class="col-sm-6" style="position: absolute;top: 235px;">
                           <div class="contact-info">
                               <h4>{{$t('contact.address')}}</h4>
                               <ul class="contact-address">
                                   <li><i class="fa fa-map-marker fa-lg"></i>&nbsp; {{$t('contact.address_desc')}}</li>
                                   <li><i class="fa fa-user"></i>&nbsp; {{$t('contact.manage')}}</li>
                                   <li><i class="fa fa-phone"></i>&nbsp; 9686028110</li>
                                   <!-- <li><i class="fa fa-print"></i>&nbsp; 1 -234 -456 -7890</li> -->
                                   <li><i class="fa fa-envelope"></i> jintian_ss@163.com</li>
                                   <!-- <li><i class="fa fa-skype"></i> Unika-Design</li> -->
                               </ul>
                           </div>
                       </div>
                                                                           
                   </div> <!-- /.row -->
               </div> <!-- /.container -->
           </div>
       </section>
       <!-- End contact section -->
           
           

           
       <!-- Begin footer -->
       <footer class="text-off-white">
           <div class="footer">
               <div class="container text-center wow fadeIn" data-wow-delay="0.4s">
                   <p class="copyright">Copyright &copy; 2023 - {{$t('contact.description')}}</p>
               </div>
           </div>

       </footer>
       <!-- End footer -->

       <a href="#" class="scrolltotop"><i class="fa fa-arrow-up"></i></a> <!-- Scroll to top button -->
</template>

<script>
export default {

}
</script>

<style>

</style>