export default{
    about: {
        h2_f1: "关于我们",
        description1:"金田服饰有限公司始创于2018年，公司坐落于安徽省安庆市宿松服装产业园经开区龙飞路8号1幢，公司总面积达2000多平方，标准化的工业厂房，合理的区域划分，严格按照实行标准化的6S及ISO9001质量体系要求生产经营管理。",
        description2:"目前公司的OEM、ODM贴牌及加工业务分布海内外，有国内知名品牌，国内电商、跨境电商，日本、韩国、俄罗斯、土耳其、美国及欧洲等国家的知名品牌；",
        description3:"公司主要从事国内外春、夏、秋、冬服装的设计研发、生产、进出口贸易、品牌供货、电子商务供货、医疗防护产品制造等多个领域；生产各类中高档男、女时装、户外装、童装、职业装、校服等全品类服装产品，为国内外众多品牌客户提供优质产品和完善的供应链服务，是国内外众多知名品牌的核心供应商之一；产品远销海内外，并得到了国内外客户一致好评及认可。",
        description4:"公司硬件设施齐全，拥有先进的全自动智能化充绒机、充棉机、智能自动模板绗缝机、花样机及各种专用进口特种设备，足以应对任何服装生产对设备的需求。人员配置齐全，拥有技术过硬高素质熟练缝纫工100余人，4条标准满配的流水生产线；拥有研发团队、裁剪人员、后整理检验、包装人员、公司管理人员50余人；高效的管理使得每月生产能力可达2~3万件。",
        description5:"公司推崇独特的企业文化，来提升核心竞争力。公司积极履行社会职责，创建的第二年即被宿松服装行业协会授予副会长单位。公司秉承“行远必自迩、追求无止境”的理念，诚信经营。",
        description6:"我们坚信，天道酬勤。在广大客户的支持和全厂职工的不懈努力下，我们一定能得到健康、高速发展，实现客户、员工、工厂，三方共赢。我们将笃行不怠、奋进不息，努力打造成行业标杆，造福一方。",
        evaluate:"评价",
        evaluate_title:"对我们的评价",
        evaluate_desc1:"公司拥有一流的团队,每一位员工都是有激情,有智慧的人才,他们都把自己的心思投入到公司的发展之中",
        evaluate_desc2:"公司设有完善的管理制度,帮助每一位员工在工作中营造一种良好的氛围,使员工都能安心工作!",
        evaluate_desc3:"公司的技术水平一流,众多的专家以及高素质的技术人员能够将技术应用于实际,为公司创造更大的价值!",
        anonymous:"匿名",
        p1:"金田服饰是一家综合性企业，集科研设计、生产和销售于一体。",
        p2:"总部位于北京，开发品牌WHY SHINE和MANSTAFF。我们的代表处位于莫斯科。",
        p3:"我们生产羽绒服、大衣、风衣、外套等服装，以及服装配件、包装材料、五金配件、标签等。",
        p4:"中国的生产覆盖了全方位的服务。"
    },
    navbar:{
        home:"主页",
        about:"关于",
        product:"产品展示",
        team:"团队",
        contact:"联系我们",
    },
    product:{
        h2_f2:"产品展示",
        menswear:"男装",
        frock:"女装",
        children:"童装",
        configuration:"我们的配置",
        configuration_desc1:"技术过硬高素质熟练缝纫工",
        configuration_desc2:"标准满配的流水生产线",
        configuration_desc3:"每月生产能力",
        configuration_desc4:"公司总面积"
    },
    team:{
        h2_f3:"我们的团队",
        team_desc1:"先进的制版设备",
        team_desc2:"智能全自动化的充绒设备",
        team_desc3:"宽敞明亮的工作环境",
        team_desc4:"勤劳努力奋斗的金田人",
        team_desc5:"智能自动化模板绗缝机",
        team_desc6:"高配置人性化的员工宿舍楼",
        h2_f4:"合作品牌"
    },
    contact:{
        follow:"关注我们",
        h2_f5:"联系我们",
        address:"地址",
        address_desc:"安徽省安庆市宿松服装产业园经开区龙飞路8号1幢",
        manage:"张丽丽",
        letter:"给我们写信",
        name:"姓名",
        mailbox:"邮箱",
        problem:"问题",
        information:"信息",
        send:"发送",
        description:"金田服饰有限公司",
    }
}