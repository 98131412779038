<template>
	<section id="text-carousel-intro-section" class="parallax" data-stellar-background-ratio="0.5" style="background-color: white;height: 435px;margin-bottom: 20px;">
				<div class="container">
					<div class="caption text-center text-white" data-stellar-ratio="0.7">
						<div style="width: 50%;height: 240px;display: inline-block;padding-right: 70px;border-right: 2px solid #000;">
							<img src="../../public/img/MANSTAFF.png" style="width: 350px;height: 240px;">
						</div>
						<div style="width: 50%;height: 240px;display: inline-block;padding-left: 70px;">
							<img src="../../public/img/WHYSHINE.png" style="width: 350px;height: 240px;">
						</div>
					</div> 
				</div> <!-- /.container -->
	</section>
	<div style="width: 100%;height: 150px;background-color: #363940;text-align: center;;">
		<p style="color: #efefef;font-size: 16px;">{{$t('about.p1')}}
		 </p>
		<p style="color: #efefef;font-size: 16px;">{{$t('about.p2')}}
		</p>
		<p style="color: #efefef;font-size: 16px;">
			{{$t('about.p3')}}
		</p>
		<p style="color: #efefef;font-size: 16px;">
			{{$t('about.p4')}}
		</p>
		<p style="color: #efefef;font-size: 16px;">
			Менеджер: Фируза   Телефон: 7(903)288-84-69   электронная почта: i@llc-sinus.ru
		</p>
		<p style="color: #efefef;font-size: 16px;">
			Город Москва, улица Нижняя Красносельская, дом 40/12, корпус 21 ,этаж 2, помещение 229А, Деловой центр «Новь».
		</p>
	</div>
</template>

<script>
export default {

}
</script>

<style>

</style>