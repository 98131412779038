// import Vue from 'vue'
import { createApp } from 'vue'
import App from '@/App.vue'
import { createI18n } from 'vue-i18n'
import Cookies from 'js-cookie'
import enLocale from './en' // 导入项目中用到的英文语言包
import zhLocale from './zh'// 导入项目中用到的中文语言包
import ruLocale from './ru'
const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  },
  ru: {
    ...ruLocale
  },
}
const i18n = createI18n({
  locale: localStorage.getItem('language') || 'ru', // 设置语种
  messages, // 设置全局当地语言包,
  fallbackLocale: 'ru',
})

export default i18n