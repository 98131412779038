<template>
    
			<!-- End counter up -->
                
                
                
                
            <!-- Begin team-->
            <section id="team-section" class="page">
                <!-- Begin page header-->
                <div class="page-header-wrapper">
                    <div class="container">
                        <div class="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                            <h2>{{$t('team.h2_f3')}}</h2>
                            <div class="devider"></div>
                            <!-- <p class="subtitle">Meat our experts</p> -->
                        </div>
                    </div>
                </div>
                <!-- End page header-->
                <div class="container">
                    <div class="row">
                        <div class="team-items">
                            <div class="team-box"><img src="../assets/img/team/2.png" alt="">
                                <p>{{$t('team.team_desc1')}}</p>
                            </div>
                            <div class="team-box"><img src="../assets/img/team/6.png" alt="">
                                <p>{{$t('team.team_desc2')}}</p>
                            </div>
                            <div class="team-box"><img src="../assets/img/team/5.png" alt="">
                                <p>{{$t('team.team_desc3')}}</p>
                            </div>
                            <div class="team-box"><img src="../assets/img/team/4.png" alt="">
                                <p>{{$t('team.team_desc4')}}</p>
                            </div>
                            <div class="team-box"><img src="../assets/img/team/3.png" alt="">
                                <p>{{$t('team.team_desc5')}} </p>
                            </div>
                            <div class="team-box"><img src="../assets/img/team/1.png" alt="">
                                <p>{{$t('team.team_desc6')}}</p>
                            </div>
                            <div class="clearfix"></div>
                    </div>
                    </div>
                </div>

            </section>
            <!-- End team-->
                
                
                
                
            <!-- Begin partners -->
            <section id="partners-section">
                <!-- Begin page header-->
                <div class="page-header-wrapper">
                    <div class="container">
                        <div class="page-header text-center wow fadeInDown" data-wow-delay="0.4s" >
                            <h2>{{$t('team.h2_f4')}}</h2>
                            <div class="devider"></div>
                            <!-- <p class="subtitle">Those who trust us</p> -->
                        </div>
                    </div>
                </div>
                <!-- End page header-->
                <div class="container">
                    <div style="margin-left: 60px;">
                        <div class="boxes"><img src="../assets/img/partners/1.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/2.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/3.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/4.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/5.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/6.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/7.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/8.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/9.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/10.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/11.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/1.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/2.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/3.png" alt="img"></div>
                        <div class="boxes"><img src="../assets/img/partners/4.png" alt="img"></div>
                    </div>
                </div>
            </section>
</template>

<script>
export default {

}
</script>

<style>
</style>