<template>
<nav id="main-navbar" class="navbar navbar-default navbar-fixed-top" role="navigation"> <!-- Classes: navbar-default, navbar-inverse, navbar-fixed-top, navbar-fixed-bottom, navbar-transparent. Note: If you use non-transparent navbar, set "height: 98px;" to #header -->

<div class="container">

  <!-- Brand and toggle get grouped for better mobile display -->
  <div class="navbar-header">
    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <a class="navbar-brand page-scroll" href="/" style="color: #FFFFFF;font-size: 22px;"> S I N U S </a>
  </div>

  <!-- Collect the nav links, forms, and other content for toggling -->
  <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" >
      <ul class="nav navbar-nav navbar-right">
          <li><a class="page-scroll" href="/">{{$t('navbar.home')}}</a></li>
          <li><a class="page-scroll" href="#about-section">{{$t('navbar.about')}}</a></li>
          <!-- <li><a class="page-scroll" href="#services-section">服务</a></li> -->
          <li><a class="page-scroll" href="#portfolio-section">{{$t('navbar.product')}}</a></li>
          <li><a class="page-scroll" href="#team-section">{{$t('navbar.team')}}</a></li>                            
          <!-- <li><a class="page-scroll" href="#prices-section">Prices</a></li> -->
          <li><a class="page-scroll" href="#contact-section">{{$t('navbar.contact')}}</a></li>
          <div class="language">
            <a href="#" :class="contry=='ru'?'active':''" @click="changeLang('ru')">Русский язык</a> &nbsp;&nbsp;|&nbsp;
            <a href="#" :class="contry=='en'?'active':''" @click="changeLang('en')">English</a> &nbsp;&nbsp;|&nbsp;
            <a href="#" :class="contry=='zh'?'active':''" @click="changeLang('zh')">中文</a> 

          </div>
      </ul>
  </div><!-- /.navbar-collapse -->
</div><!-- /.container -->
</nav>
</template>

<script>
import i18n from '@/i18n'
export default {
    data(){
        return {
          contry: "ru",
        }
    },
    methods: {
      changeLang(contry){
        this.contry = contry;
        i18n.global.locale = this.contry;
      }
    }
 }
</script>

<style>

</style>