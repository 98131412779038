export default{
    about: {
        h2_f1: "О нас",
        description1:"Jintian Clothing Co., Ltd. была основана в 2018 году. Компания расположена в здании 1, № 8 Longfei Road, Зона экономического развития, промышленный парк одежды Susong, город Аньцин, провинция Аньхой. Компания имеет общую площадь более 2000 квадратных метро в, стандартизированные промышленные предприятия, разумное региональное подразделение, а также управление производством и эксплуатацией в строгом соответствии с внедрением стандартизированных требований к системе качества 6S и ISO9001.",
        description2:"В настоящее время OEM, ODM и перерабатывающий бизнес компании распространяется внутри страны и за рубежом. Существуют известные отечественные бренды, внутренняя электронная коммерция, трансграничная электронная коммерция, а также известные бренды в Японии, Южной Корее, России, Турции, Соединенных Штатах и Европе.",
        description3:"Компания в основном занимается дизайном, разработкой, производством, импортом и экспортом, поставками брендов, электронной коммерцией, производством средств медицинской защиты и другими областями весенней, летней, осенней и зимней одежды в стране и за рубежом; она производит все виды высококачественной мужской и женской одежды. мода, верхняя одежда, детская одежда, профессиональная одежда, школьная форма и другие товары для одежды полного ассортимента, предоставляя высококачественную продукцию и безупречные услуги цепочки поставок для многих клиентов бренда в стране и за рубежом. Это один из основных поставщиков многих известных брендов в стране и за рубежом; его продукция экспортируется в страну и за рубеж, и была хорошо принята и признана клиентами в стране и за рубежом.",
        description4:"Компаниярасполагает полным оборудованием, включая передовую автоматическую интеллектуальную разливочную машину, машину для наполнения хлопка, интеллектуальную автоматическую машину для выстегивания шаблонов, машину для выкройки и различное специальное импортно оборудование, которого достаточно для удовлетворения потребностей в оборудовании любого швейного производства.Штат полностью укомплектован более чем 100 высококвалифицированными и высококачественными швейными работниками и 4 стандартными сборочнымилиниями; в нем работает более 50 научно-исследовательских групп, персонал по раскрою, отделочному контролю, персонал по упаковке и управленческий персонал компании; эффективное управление позволяет ежемесячной производственной мощности достигать от 20 000 до 30 000 штук.",
        description5:"Компания уважает уникальную корпоративную культуру для повышения своей основной конкурентоспособности.Компания активно выполняет свои социальные обязательства и на второй год своего основания была удостоена звания вице-президента Ассоциации швейно й промышленности Сусонга.Компании, придерживающиеся концепции “дальние путешествия должны быть самостоятельными, стремление к ним бесконечно”, и действуют добросовестно.",
        description6:"Мы твердо верим, что Небеса вознаграждают усердие.При поддержке наших клиентов и неустанных усилиях сотрудников всей фабрики мы определенно сможем добиться дорового и быстрого развития и добиться беспроигрышной ситуации для клиентов, сотрудников и фабрик. Мы будем упорствовать и продвигаться вперед, а также стремиться стать эталоном отрасли.  ",
        evaluate:"Оценка",
        evaluate_title:"О нашей оценке ",
        evaluate_desc1:"У компании есть первоклассная команда, и каждый сотрудник - страстный, умный человек, который вкладывает свои мысли в развитие компании. ",
        evaluate_desc2:"У компании есть первоклассная команда, каждый сотрудник - страстный, умный талант, все они вкладывают свои мысли в развитие компании Компания имеет надежную систему управления, чтобы помочь каждому сотруднику создать хорошую атмосферу на работе, чтобы сотрудники могли спокойно работать!",
        evaluate_desc3:"Технологический уровень компании, большое количество экспертов и высококвалифицированных технических специалистов способны применять технологии на практике, создавая большую ценность для компании!",
        anonymous:"Анонимный",
        p1:"LLC SINUS (ООО СИНУС) комплексное предприятие, объединяющее научно-исследовательские и опытно-конструкторские работы,",
        p2:"производство и продажи. Головной офис находится в Пекине.развивает бренд WHY SHINE,MANSTAFF. Наше представительство находится в Москве.",
        p3:"Пуховики, куртки, тренчи, пальто и другое а также производство аксессуаров для одежды, упаковочные материалы, фурнитуры, этикетки и много",
        p4:"другое.Производство в Китае охватывает полный спектр услуг."
    },
    navbar:{
        home:"Домашняя страница",
        about:"О",
        product:"Презентация продукции",
        team:"Команды",
        contact:"Свяжитесь с нами",
    },
    product:{
        h2_f2:"Презентация продукции",
        menswear:"Мужская одежда",
        frock:"Женская одежда",
        children:"Детская одежда",
        configuration:"Наша конфигурация",
        configuration_desc1:"Высококвалифицированные швейные рабочие",
        configuration_desc2:"Стандартная полноводная линия",
        configuration_desc3:"Ежемесячная производственная мощность",
        configuration_desc4:"Общая площадь компании"
    },
    team:{
        h2_f3:"Наша команда.",
        team_desc1:"Современное оборудование для изготовления лекал",
        team_desc2:"Интеллектуальное и полностью автоматизированное         оборудование для набивки пухом ",
        team_desc3:"Просторная и светлая рабочая среда ",
        team_desc4:"трудолюбивый и профессиональный персонал",
        team_desc5:"Интеллектуальная автоматизированное оборудование для оверлока",
        team_desc6:"Высокое общежития с чистыми  и просторными комнатами",
        h2_f4:"Партнерские  марки"
    },
    contact:{
        follow:"Заботьтесь о нас",
        h2_f5:"Связаться с нами",
        address:"Адрес",
        address_desc:"Город Аньцин, провинция Аньхой",
        manage:"Чжан Лили",
        letter:"Напиши нам.",
        name:"Имя",
        mailbox:"Почтовый ящик",
        problem:"Вопросы",
        information:"Информация",
        send:"Отправить",
        description:"Цзиньтянь одежда лтд"
    }
}