<template>
  <navbar></navbar>
  <home></home>
  <about></about>
  <product></product>
  <team></team>
  <contact></contact>
</template>

<script>
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import Home from './views/Home.vue'
import Navbar from './views/Navbar.vue'
import Product from './views/Product.vue'
import Team from './views/Team.vue'
export default {
  components: { Home, About, Navbar, Product, Team, Contact},
}
</script>

<style>
* { touch-action: pan-y; }
</style>
