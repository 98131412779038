import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import VueLazyload from 'vue-lazyload'




createApp(App).use(i18n).use(store).use(router).use(VueLazyload, {
    preLoad: 1.3,
    error: '',
    loading: '/img/loading.gif',
    attempt: 1
  }).mount('#app')
// i18n: (key, value) => i18n.t(key, value)





