<template>
  <section id="about-section" class="page bg-style1">
                <!-- Begin page header-->
                <div class="page-header-wrapper">
                    <div class="container">
                        <div class="page-header text-center wow fadeInUp" data-wow-delay="0.3s">
                            <h2>{{$t('about.h2_f1')}}</h2>
                            <div class="devider"></div>
                            <!-- <p class="subtitle">little information</p> -->
                        </div>
                    </div>
                </div>
                <!-- End page header-->

                <!-- Begin rotate box-1 -->
                <div class="rotate-box-1-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="left">
                                <p>{{$t('about.description1')}}<br><br>
                                    {{$t('about.description2')}}<br><br>
                                    {{$t('about.description3')}}<br><br>
                                    {{$t('about.description4')}}<br><br>
                                    {{$t('about.description5')}}<br><br>
                                    {{$t('about.description6')}}
                                </p>
                            </div>
                            <div class="right">
                                <img src="../assets/img/p2.jpg" alt="">
                            </div>
                        </div> <!-- /.row -->
                    </div> <!-- /.container -->
                </div>
                <!-- End rotate box-1 -->
                
                <div class="extra-space-l"></div>
                
                <!-- Begin page header--> 
                <div class="page-header-wrapper">
                    <div class="container">
                        <div class="page-header text-center wow fadeInUp" data-wow-delay="0.3s">
                            <h4></h4>
                        </div>
                    </div>
                </div>
                <!-- End page header-->
          </section>
            <!-- Begin testimonials -->
            <section id="testimonial-section">
                <div id="testimonial-trigger" class="testimonial text-white parallax" data-stellar-background-ratio="0.5" style="background-image: url(./img/testimonial-bg.jpg);">
                    <div class="cover"></div>
                    <!-- Begin page header-->
                    <div class="page-header-wrapper">
                        <div class="container">
                            <div class="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                                <h2>{{$t('about.evaluate')}}</h2>
                                <div class="devider"></div>
                                <p class="subtitle" style="margin-bottom: 40px;">{{$t('about.evaluate_title')}}</p>
                                <div class="container">
                        <div class="testimonial-inner center-block text-center">
                            <div id="owl-testimonial" class="owl-carousel">
                                <div class="item">
                                    <blockquote>
                                        <p>"{{$t('about.evaluate_desc1')}}"</p>
                                        <footer><cite title="Source Title">{{$t('about.anonymous')}}</cite></footer>
                                    </blockquote>
                                </div>
                                <div class="item">
                                    <blockquote>
                                        <p>"{{$t('about.evaluate_desc2')}}"</p>
                                        <footer><cite title="Source Title">{{$t('about.anonymous')}}</cite></footer>
                                    </blockquote>
                                </div>
                                <div class="item">
                                    <blockquote>
                                        <p>"{{$t('about.evaluate_desc3')}}"</p>
                                        <footer><cite title="Source Title">{{$t('about.anonymous')}}</cite></footer>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                            </div>
                        </div>
                    </div>
                    <!-- End page header-->
             
                </div>
            </section>
</template>

<script>
import i18n from '@/i18n'
export default {
}
</script>

<style>
.team-item {
	height: 120px;
}	
.team-item .team-triangle {
	width: 120px;
	height: 120px;
	background: transparent;
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	margin: 0 auto;
	position: relative;
	top: 25px;
	box-shadow: 0 0 0 6px #FFFFFF, 0 0 0 7px #dadbdb;
	overflow: hidden;
}
</style>