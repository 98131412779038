import { createRouter, createWebHashHistory } from 'vue-router'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Team from '../views/Team.vue'
import Navbar from '../views/Navbar.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product
  },
  {
    path: '/Team',
    name: 'Team',
    component: Team
  },
  {
    path: '/Navbar',
    name: 'Navbar',
    component: Navbar
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
