<template>
  <section id="portfolio-section" class="page bg-style1">
            	<div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="portfolio">
                                <!-- Begin page header-->
                                <div class="page-header-wrapper">
                                    <div class="container">
                                        <div class="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                                            <h2>{{$t('product.h2_f2')}}</h2>
                                            <div class="devider"></div>
                                            <!-- <p class="subtitle">What we are proud of</p> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- End page header-->
                                <div class="portfoloi_content_area" >
                                    <div class="portfolio_menu" id="filters">
                                        <ul>
                                            <!-- <li class="active_prot_menu"><a href="#porfolio_menu" data-filter="*">全部</a></li> -->
                                            <li class="active_prot_menu"><a href="javascript:void(0);" @click="showListType=1;page=1;toPage()">{{$t('product.menswear')}}</a></li>
                                            <li><a href="javascript:void(0);"  @click="showListType=2;page=1;toPage()">{{$t('product.frock')}}</a></li>
                                            <li><a href="javascript:void(0);"  @click="showListType=3;page=1;toPage()">{{$t('product.children')}}</a></li>
                                            <!-- <li><a href="#porfolio_menu" data-filter=".GraphicDesign">graphic design</a></li>
                                            <li><a href="#porfolio_menu" data-filter=".responsive">responsive</a></li> -->
                                        </ul>
                                    </div>
                                    <div class="portfolio_content">
                                        <div class="row"  id="portfolio">
                                            <!-- pic -->
                                            <div v-if="showListType==1">
                                                <div v-for="(item,index) in currentPicsMen" :key="index" class="col-xs-12 col-sm-4">
                                                    <div class="portfolio_single_content">
                                                        <a :href="item.url" :data-idx="index">
                                                            <img v-lazy="item.url" style="border-radius: 12px !important;"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="showListType==2">
                                                <div v-for="(item,index) in currentPicsLady" :key="index" class="col-xs-12 col-sm-4">
                                                    <div class="portfolio_single_content">
                                                        <a :href="item.url" :data-idx="index">
                                                            <img v-lazy="item.url" style="border-radius: 12px !important;"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="showListType==3">
                                                <div v-for="(item,index) in currentPicsKid" :key="index" class="col-xs-12 col-sm-4">
                                                    <div class="portfolio_single_content">
                                                        <a :href="item.url" :data-idx="index">
                                                            <img v-lazy="item.url" style="border-radius: 12px !important;"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                               
                                            <!-- pic end -->
                                        </div>
                                        <div style="float: right;">
                                                <button @click="prev" style="margin-right: 30px;border: none;width: 40px;height:40px;font-size: 32px;" v-bind:disabled="!(page>1)"><i class="fa fa-angle-left" /></button>
                                                <button @click="next" style="margin-right: 15px;border: none;width: 40px;height:40px;font-size: 32px;" v-bind:disabled="vliPage()"><i class="fa fa-angle-right" /></button>
                                            </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End portfolio -->
            
                
                
                
            <!-- Begin counter up -->
            <section id="counter-section">                					
				<div id="counter-up-trigger" class="counter-up text-white parallax" data-stellar-background-ratio="0.5" style="background-image: url(/img/counter-bg.jpg);">
					<div class="cover"></div>
                    <!-- Begin page header-->
                    <div class="page-header-wrapper">
                        <div class="container">
                            <div class="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                                <h2>{{$t('product.configuration')}}</h2>
                                <div class="devider"></div>
                                <!-- <p class="subtitle">Before anyone is not told</p> -->
                            </div>
                        </div>
                    </div>
                    <!-- End page header-->
					<div class="container">

						<div class="row">

							<div class="fact text-center col-md-3 col-sm-6">
								<div class="fact-inner">
									<i class="fa fa-users fa-3x"></i>
                                    <div class="extra-space-l"></div>
									<span class="counter">100</span>
									<p class="lead">{{$t('product.configuration_desc1')}}</p>
								</div>
							</div>

							<div class="fact text-center col-md-3 col-sm-6">
								<div class="fact-inner">
									<i class="fa fa-leaf fa-3x"></i>
                                    <div class="extra-space-l"></div>
									<span class="counter">4</span>
									<p class="lead">{{$t('product.configuration_desc2')}}</p>
								</div>
							</div>

							<div class="fact text-center col-md-3 col-sm-6">
								<div class="fact-inner">
									<i class="fa fa-line-chart fa-3x"></i>
                                    <div class="extra-space-l"></div>
									<span class="counter">30000</span>
									<p class="lead">{{$t('product.configuration_desc3')}}</p>
								</div>
							</div>

							<div class="fact last text-center col-md-3 col-sm-6">
								<div class="fact-inner">
									<i class="fa fa-puzzle-piece fa-3x"></i>
                                    <div class="extra-space-l"></div>
									<span class="counter">2000</span>
									<p class="lead">{{$t('product.configuration_desc4')}}</p>
								</div>
							</div>

						</div> <!-- /.row -->
					</div> <!-- /.container -->
				</div>
            </section>
</template>

<script>
export default {
    data(){
        return {
            showListType: 1,
            page: 1,
            maxPageMen: 0,
            maxPageLady: 0,
            maxPageKid: 0,
            currentPicsMen: [],
            currentPicsLady: [],
            currentPicsKid: [],
            picsMen: [
            {url: '/img/boy/A_Bathing_Ape_2023_69165676.jpg'},
                {url: 'img/boy/A_Bathing_Ape_69165642.jpg'},
                {url: '/img/boy/A_Bathing_Ape_69165668.jpg' },
                {url: '/img/boy/A_Bathing_Ape_69165671.jpg'},
                {url: '/img/boy/A_Bathing_Ape_69165674.jpg'},
                {url: '/img/boy/Canada_Goose_69171226.jpg'},
                {url: '/img/boy/Canada_Goose_69171233.jpg'},
                {url: '/img/boy/Canada_Goose_69171244.jpg'},
                {url: '/img/boy/Canada_Goose_69171245.jpg'},
                {url: '/img/boy/Canada_Goose_69171246.jpg'},
                {url: '/img/boy/Canada_Goose_69171247.jpg'},
                {url: '/img/boy/DIGAWEL_2023_69148567.jpg'},
                {url: '/img/boy/DIGAWEL_69148568.jpg'},
                {url: '/img/boy/DIGAWEL_69148579.jpg'},
                {url: '/img/boy/DIGAWEL_69148589.jpg'},
                {url: '/img/boy/Hermes_69161683.jpg'},
                {url: '/img/boy/Hermes_69161684.jpg'},
                {url: '/img/boy/Manastash_68100122.jpg'},
                {url: '/img/boy/Manastash_68100133.jpg'},
                {url: '/img/boy/Manastash_68100224.jpg'},
                {url: '/img/boy/Manastash_68100252.jpg'},
                {url: '/img/boy/River_stone_69115346.jpg'},
                {url: '/img/boy/River_stone_69115371.jpg'},
                {url: '/img/boy/River_stone_69115372.jpg'},
                {url: '/img/boy/River_stone_69115382.jpg'},
                {url: '/img/boy/Roarguns_69164501.jpg'},
                {url: '/img/boy/Roarguns_69164647.jpg'},
                {url: '/img/boy/Roarguns_69164694.jpg'},
                {url: '/img/boy/Roarguns_69164782.jpg'},
                {url: '/img/boy/Roarguns_69164820.jpg'},
                {url: '/img/boy/Roarguns_69164927.jpg'},
                {url: '/img/boy/Roberto_Cavalli_69161761.jpg'},
                {url: '/img/boy/Roberto_Cavalli_69161774.jpg'},
                {url: '/img/boy/Roberto_Cavalli_69161775.jpg'},
                {url: '/img/boy/Roberto_Cavalli_69161793.jpg'},
                {url: '/img/boy/Sasquatchfabrix_69148862.jpg'},
                {url: '/img/boy/Sasquatchfabrix_69148907.jpg'},
                {url: '/img/boy/Sasquatchfabrix_69148912.jpg'},
                {url: '/img/boy/Sasquatchfabrix_69148916.jpg'},
                {url: '/img/boy/Stone_Island_69109023.jpg'},
                {url: '/img/boy/Stone_Island_69109048.jpg'},
                {url: '/img/boy/Stone_Island_69109056.jpg'},
                {url: '/img/boy/Zegna_69189330.jpg'},
                {url: '/img/boy/Zegna_69189332.jpg'},
                {url: '/img/boy/Zegna_69189344.jpg'},
                {url: '/img/boy/Zegna_69189359.jpg'},
                {url: '/img/boy/lilbetter_69115275.jpg'},
                {url: '/img/boy/lilbetter_69115276.jpg'},
                {url: '/img/boy/overcoat_69141186.jpg'},
            ],
            picsLady: [
            {url: '/img/female/A_Bathing_Ape_69165711.jpg'},
                {url: '/img/female/A_Bathing_Ape_69165714.jpg'},
                {url: '/img/female/HUGO_BOSS_69174575.jpg'},
                {url: '/img/female/HUGO_BOSS_69174583.jpg'},
                {url: '/img/female/HUGO_BOSS_69174585.jpg'},
                {url: '/img/female/HUGO_BOSS_69174587.jpg'},
                {url: '/img/female/HUGO_BOSS_69174588.jpg'},
                {url: '/img/female/HUGO_BOSS_69174589.jpg'},
                {url: '/img/female/HUGO_BOSS_69174594.jpg'},
                {url: '/img/female/HUGO_BOSS_69174599.jpg'},
                {url: '/img/female/HUGO_BOSS_69174602.jpg'},
                {url: '/img/female/HUGO_BOSS_69174606.jpg'},
                {url: '/img/female/HUGO_BOSS_69174608.jpg'},
                {url: '/img/female/HUGO_BOSS_69174609.jpg'},
                {url: '/img/female/HUGO_BOSS_69174614.jpg'},
                {url: '/img/female/JieDa_69011283.jpg'},
                {url: '/img/female/JieDa_69011298.jpg'},
                {url: '/img/female/JieDa_69011323.jpg'},
                {url: '/img/female/JieDa_69011344.jpg'},
                {url: '/img/female/JieDa_69011365.jpg'},
                {url: '/img/female/Manastash_68100233.jpg'},
                {url: '/img/female/Nili_Lotan_67618747.jpg'},
                {url: '/img/female/Nili_Lotan_67618753.jpg'},
                {url: '/img/female/Nili_Lotan_67618755.jpg'},
                {url: '/img/female/Nili_Lotan_67618789.jpg'},
                {url: '/img/female/WE11DONE_67608309.jpg'},
                {url: '/img/female/WE11DONE_67608348.jpg'},
                {url: '/img/female/WE11DONE_67608357.jpg'},
                {url: '/img/female/WE11DONE_67608435.jpg'},
                {url: '/img/female/WE11DONE_67608458.jpg'},
                {url: '/img/female/WE11DONE_67608540.jpg'},
                {url: '/img/female/WE11DONE_67608565.jpg'},
                {url: '/img/female/WE11DONE_67608682.jpg'},
                {url: '/img/female/WE11DONE_67608786.jpg'},
                {url: '/img/female/WE11DONE_67608916.jpg'},
                {url: '/img/female/jacket_69159740.jpg'},
                {url: '/img/female/jacket_69159741.jpg'},
                {url: '/img/female/overcoat_69159702.jpg'},
                {url: '/img/female/skirt_69159730.jpg'},
                {url: '/img/female/suit_69159727.jpg'},
            ],
            picsKid: [
               // {url: Bathing},
                {url: '/img/baby/Bape_Kids_69166134.jpg'},
                {url: '/img/baby/Bape_Kids_69166135.jpg'},
                {url: '/img/baby/Bape_Kids_69166136.jpg'},
                {url: '/img/baby/Bape_Kids_69166137.jpg'},
                {url: '/img/baby/Bape_Kids_69166141.jpg'},
                {url: '/img/baby/Bape_Kids_69166143.jpg'},
                {url: '/img/baby/Bape_Kids_69166144.jpg'},
                {url: '/img/baby/Bape_Kids_69166145.jpg'},
                {url: '/img/baby/Bape_Kids_69166146.jpg'},
                {url: '/img/baby/Bape_Kids_69166150.jpg'},
                {url: '/img/baby/Bape_Kids_69166151.jpg'},
                {url: '/img/baby/Bape_Kids_69166153.jpg'},
                {url: '/img/baby/Crywolf_69070081.jpg'},
                {url: '/img/baby/Crywolf_69070102.jpg'},
                {url: '/img/baby/Crywolf_69070108.jpg'},
                {url: '/img/baby/Erdos_69070082.jpg'},
                {url: '/img/baby/Erdos_69070084.jpg'},
                {url: '/img/baby/Erdos_69070093.jpg'},
                {url: '/img/baby/Erdos_69070095.jpg'},
                {url: '/img/baby/Erdos_69070097.jpg'},
                {url: '/img/baby/Erdos_69070099.jpg'},
                {url: '/img/baby/Off-White_69142319.jpg'},
                {url: '/img/baby/Off-White_69142324.jpg'},
                {url: '/img/baby/Off-White_69142327.jpg'},
                {url: '/img/baby/Teenie_Weenie_69122264.jpg'},
                {url: '/img/baby/Teenie_Weenie_69122271.jpg'},
                {url: '/img/baby/Teenie_Weenie_69122280.jpg'},
                {url: '/img/baby/Teenie_Weenie_69122281.jpg'},
                {url: '/img/baby/Teenie_Weenie_69122283.jpg'},
                {url: '/img/baby/Teenie_Weenie_69122284.jpg'},
                {url: '/img/baby/Teenie_Weenie_69122299.jpg'},
                {url: '/img/baby/Timberland_69109764.jpg'},
                {url: '/img/baby/Timberland_69109792.jpg'},
                {url: '/img/baby/Timberland_69109793.jpg'},
                {url: '/img/baby/Timberland_69109794.jpg'},
                {url: '/img/baby/cardigan_69187925.jpg'},
                {url: '/img/baby/eiderdown_69187923.jpg'},
                {url: '/img/baby/shirt_69166149.jpg'},
                {url: '/img/baby/shirt_69187956.jpg'},
            ],
        }
    },
    mounted() {
        this.maxPageMen = ((this.picsMen.length+9) / 9);
        this.maxPageLady = ((this.picsLady.length+9) / 9);
        this.maxPageKid = ((this.picsKid.length+9) / 9);
        this.toPage();
        $(function(){
                // $('.dowebok a').SimpleLightbox();
                let $gallery = new SimpleLightbox('.portfolio_single_content a', {});
            });
    },
    methods: {
        toPage(){
            let pics = [];
            switch(this.showListType){
                case 1:
                    pics = this.picsMen;
                    break;
                case 2:
                    pics = this.picsLady;
                    break;
                case 3:
                    pics = this.picsKid;
                    break;
            }
            let currentPics = [];
            console.log('show page:'+this.page);
            let s = (this.page-1)*9;
            let e = s+9;
            e = e<pics.length?e:pics.length;
            for (var i = s; i < e; i++) {
                currentPics.push(pics[i]);
            }
            switch(this.showListType){
                case 1:
                    this.currentPicsMen = currentPics;
                    break;
                case 2:
                    this.currentPicsLady = currentPics;
                    break;
                case 3:
                    this.currentPicsKid = currentPics;
                    break;
            }
        },
        prev(){
            this.page -= 1;
            this.toPage();
        },
        next(){
            this.page += 1;
            this.toPage();
        },
        vliPage(){
            let max = 0;
            switch(this.showListType){
                case 1:
                    max = this.maxPageMen;
                    break;
                case 2:
                    max = this.maxPageLady;
                    break;
                case 3:
                    max = this.maxPageKid;
                    break;
            }
            if(this.page < max - 1){
                return false;
            }else{
                return true;
            }
        }
    }
}
</script>

<style>

</style>